import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../global/css/patient-list.css";
import api from "../../api";
import Topbar from "../dashboard/Topbar";
import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    Link,
} from "@chakra-ui/react";
import SearchIcon from "../../components/icons/SearchIcon";
import FilterIcon from "../../components/icons/FilterIcon";
import { toast } from "react-toastify";
import PlusSquareIcon from "../../components/icons/PlusSquareIcon";
import UserGear from "../../components/icons/UserGear";
import DataTable from "react-data-table-component";
import EditIcon from "../../components/icons/EditIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { handlePermissionValid } from "../../permission/permission";

const UserRoles = () => {
    const [roleList, setRoleList] = useState([]);
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortColumn, setSortColumn] = useState("name"); // Default sort column
    const [sortDirection, setSortDirection] = useState("asc"); // Default sort direction
    const [addUserModel, setAddUserModel] = useState(false); // Open model for add User
    const navigate = useNavigate();


    const [isEditPermission, setISEditPermission] = useState(false);
    const [isAddPermission, setISAddPermission] = useState(false);
    const [isDeletePermission, setISDeletePermission] = useState(false);

    useEffect(() => {
        let add = handlePermissionValid('/userrole', "Users", 'INSERT')
        let edit = handlePermissionValid('/userrole', "Users", 'EDIT')
        let deletePer = handlePermissionValid('/userrole', "Users", 'DELETE')
        setISDeletePermission(deletePer)
        setISAddPermission(add);
        setISEditPermission(edit)
    }, [])

    useEffect(() => {
        const params = {
            searchValue: searchValue,
            length: 150, // Number of items per page
            start: (page - 1) * 10, // Calculate the starting index
            draw: page,
            status: "",
            sortColumn: sortColumn,
            sortDirection: sortDirection,
        };

        getRoleDataList(params);
    }, [page, searchValue, sortColumn, sortDirection]);

    const onSearchValueChange = (e) => {
        setSearchValue(e.target.value);
        setPage(1); // Reset to the first page when search value changes
    };

    const onSort = (column, direction) => {
        setSortColumn(column.sortField);
        setSortDirection(direction);
        setPage(1); // Reset to the first page on sort change
    };

    const getRoleDataList = async (params) => {
        try {
            const response = await api.get("/user/role/getall", { params });
            if (response.data.status) {
                setRoleList(response.data.response);
                // setTotalPages(
                //     Math.ceil(response.data.response.recordsTotal / params.length)
                // );
                // setTotalRecords(response.data.response.recordsTotal);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const [perPage, setPerPage] = useState(10);

    const onRowsPerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
        setPage(1); // Reset to first page on rows per page change
    };


    useEffect(() => {
        const params = {
            start: (page - 1) * perPage, // Calculate starting index
            length: perPage, // Number of items per page
            // other parameters like searchValue if applicable
            start: (page - 1) * 10, // Calculate the starting index
            draw: page, // Current page number
            status: "", // Status filter, if needed
        };
        getRoleDataList(params); // Trigger data fetching with new pagination parameters
    }, [page, perPage]);

    const handleCreate = (id) => {
        if (id) {
            navigate(`/roles-permissions/${id}`)
        } else {
            navigate(`/roles-permissions/create`)
        }
    }

    useEffect(() => {
        handleFetchPermissionTableData();
    }, [])


    const handleFetchPermissionTableData = async (id) => {
        try {
            const response = await api.get("/user/role/2");
            if (response.data.status) {
                localStorage.setItem('rolestable', JSON.stringify(response.data.response.menupermissions))
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleDelete = async (id) => {
        try {
            const response = await api.get(`/user/role/${id}/delete`);
            if (response.data.status) {
                const params = {
                    start: (page - 1) * perPage,
                    length: perPage,
                    start: (page - 1) * 10,
                    draw: page,
                    status: "",
                };
                getRoleDataList(params);
                toast.success('Role deleted successfully.');
            }
        } catch (error) {
        } finally {
        }
    }

    const columns = [
        {
            name: "#",
            className: "table-header-title",
            selector: (row) => `${row?.userRoleId}`,
            sortable: true,
        },
        {
            name: "Roles",
            className: "table-header-title",
            selector: (row) => {
                return <p style={{ color: "#4C8BF5", fontWeight: 600 }}>{row?.userRoleName}</p>
            },
            sortable: true,
            sortField: 'roles',
            center: true,
        },
        {
            name: "Action",
            className: "table-header-title",
            selector: (row) => {
                let hrefLink = `/roles-permissions/${row.userRoleId}`;
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {isEditPermission && <p onClick={() => handleCreate(row.userRoleId)} style={{ color: "#4C8BF5", fontWeight: 600, cursor: 'pointer' }}><EditIcon /></p>}
                        {isDeletePermission && <p onClick={() => handleDelete(row.userRoleId)} style={{ color: "#4C8BF5", fontWeight: 600, cursor: 'pointer' }}><DeleteIcon /></p>}
                    </div>
                );
            },
            sortable: false,
            center: true,
        },
    ];


    return (
        <Box
            display="flex"
            flexDirection="column"
            w={"100%"}
            h={"100vh"}
            bg={"#F8FAFF"}
        >
            <Box as="header">
                <Topbar title="Users" />
            </Box>
            <Box
                m={"0 16px 16px"}
                pt={"16px"}
                borderRadius="8px 8px 0px 0px"
                boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
                bg={"white"}
            >
                <Flex
                    p={"0px 16px 16px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Text color="#333" fontSize={"20px"} fontWeight={700}>
                        {"Roles and Permissions"}
                    </Text>
                    <Flex
                        gap="16px"
                        alignItems={"center"}
                        justifyContent={"space-around"}
                    >
                        {isAddPermission && (
                            <Box
                                cursor={"pointer"}
                                onClick={() => handleCreate()}
                            >
                                <PlusSquareIcon />
                            </Box>
                        )}
                        <InputGroup w={"auto"}>
                            <InputLeftElement pointerEvents="none">
                                <SearchIcon size={"20px"} />
                            </InputLeftElement>
                            <Input
                                w={"244px"}
                                color={"#747474"}
                                _placeholder={{ fontSize: "12px" }}
                                placeholder="search by name, sdc, age,..."
                                border={"1px solid #4C8BF5"}
                                onChange={onSearchValueChange}
                                value={searchValue}
                            />
                        </InputGroup>
                    </Flex>
                </Flex>
                <DataTable
                    columns={columns}
                    data={roleList}
                    direction="auto"
                    fixedHeader
                    fixedHeaderScrollHeight="auto"
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRecords} // Total records across all pages
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[10, 25, 50]}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={onRowsPerPageChange}
                    onSort={onSort}
                    sortServer
                    sortField={sortColumn}
                    sortDirection={sortDirection}
                    // selectableRows
                    noDataComponent={
                        <Box p={5} m={5}>
                            No data available, Please reload to view all data...!!!
                        </Box>
                    }
                />
            </Box>
        </Box>
    );
};

export default UserRoles;
