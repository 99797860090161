function generateToothChart(data, casePaperId, toothChartIds) {
  // Initialize the base structure for each toothChartType
  const toothChartBase = {
    missing: {
      toothChartType: "missing",
      toothChartMode: "123",
      toothChartId: toothChartIds[0],
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId,
    },
    roots: {
      toothChartType: "roots",
      toothChartMode: "123",
      toothChartId: toothChartIds[1],
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId,
    },
    fill: {
      toothChartType: "fill",
      toothChartMode: "123",
      toothChartId: toothChartIds[2],
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId,
    },
    fcc: {
      toothChartType: "fcc",
      toothChartMode: "123",
      toothChartId: toothChartIds[3],
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId,
    },
    bridge: {
      toothChartType: "bridge",
      toothChartMode: "123",
      toothChartId: toothChartIds[4],
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId,
    },
    implant: {
      toothChartType: "implant",
      toothChartMode: "123",
      toothChartId: toothChartIds[5],
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId,
    },
    rct: {
      toothChartType: "rct",
      toothChartMode: "123",
      toothChartId: toothChartIds[6],
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId,
    },
    caries: {
      toothChartType: "caries",
      toothChartMode: "123",
      toothChartId: toothChartIds[7],
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId,
    },
    impactedretained: {
      toothChartType: "impactedretained",
      toothChartMode: "123",
      toothChartId: 0,
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId: 0,
    },
    presentteeth: {
      toothChartType: "presentteeth",
      toothChartMode: "123",
      toothChartId: 0,
      upperRowLeft: "",
      upperRowRight: "",
      lowerRowLeft: "",
      lowerRowRight: "",
      casePaperId: 0,
    },
  };

  // Deep clone the base structure to avoid mutation
  const toothChart = JSON.parse(JSON.stringify(toothChartBase));

  // Iterate through the input data and update the corresponding fields
  data.forEach(item => {
    const [type, row, position] = item.split('-');
    if (toothChart[type]) {
      toothChart[type][row] += (toothChart[type][row] ? ',' : '') + position;
    }
  });

  // Convert the result object to an array
  const result = Object.values(toothChart);
  
  return result;
}


export {
  generateToothChart
}