import { Box, Button, Text, VStack, HStack, Flex, Input, Select } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Topbar from "../Topbar";
import PatientStaticInfo from "./PatientStaticInfo";
import { useParams } from "react-router-dom";
import api from "../../../api";
import "../../../components/Sidebar/sidebar.css";
import PlusSquareIcon from "../../../components/icons/PlusSquareIcon";
import "../../../global/css/transaction-table.css";
import {
  MdOutlineArrowDropDownCircle,
  MdExpandLess,
  MdExpandMore,
} from "react-icons/md";
import DatePicker from "react-datepicker";
import OnlineIcon from "../../../components/icons/OnlineIcon";
import CashIcon from "../../../components/icons/CashIcon";
import CustomDatePicker from "../../../components/CustomDatePicker";
import moment from "moment";
import { toast } from "react-toastify";
import { handlePermissionValid } from "../../../permission/permission";

function TransactionView() {
  const param = useParams();
  const [patient, setPatient] = useState([]);
  const [dueAmount, setDueAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showFull, setShowFull] = useState(false);
  const [openNewCase, setOpenNewCase] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const tempAddTrans = {
    paymentTransactionItemId: "0",
    paymentTransactionId: "1",
    paidAmount: null,
    assignee: null,
    paymentMode: null,
    dueAmount: 0,
  }
  const [addTransactionData, setAddTransactionData] = useState(tempAddTrans)
  const addTransactionDataref = useRef(tempAddTrans)

  const [isEditPermission, setISEditPermission] = useState(false);
  const [isAddPermission, setISAddPermission] = useState(false);
  const [isDeletePermission, setISDeletePermission] = useState(false);
  const [isViewPermission, setISViewPermission] = useState(false);

  useEffect(() => {
    let add = handlePermissionValid('/transaction', "Transactions", 'INSERT')
    let edit = handlePermissionValid('/transaction', "Transactions", 'EDIT')
    let deletePer = handlePermissionValid('/transaction', "Transactions", 'DELETE')
    let view = handlePermissionValid('/transaction', "Transactions", 'VIEW')
    setISAddPermission(add);
    setISEditPermission(edit);
    setISDeletePermission(deletePer);
    setISViewPermission(view)
  }, [])

  const getTransactionHistory = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get(`/payment/transaction/${id}`);
      if (response.data.status) {
        let dueamount = response.data?.response?.totalFees - response.data?.response?.paidAmount
        setDueAmount(dueamount)
        setAddTransactionData({
          ...addTransactionDataref.current,
          dueAmount: dueamount
        })
        addTransactionDataref.current = {
          ...addTransactionDataref.current,
          dueAmount: dueamount
        }
        setPatient(response.data.response);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactionHistory(param.id);
  }, [param.id]);

  if (!patient) {
    return null;
  }

  const handleNewCase = () => {
    setOpenNewCase(true);
  }

  const handleInput = (e, name) => {
    if (name === 'paidAmount') {
      if (Number(e.target.value) > dueAmount) return;
      setAddTransactionData({
        ...addTransactionDataref.current,
        [name]: Number(e.target.value),
        dueAmount: dueAmount - e.target.value
      })
      addTransactionDataref.current = {
        ...addTransactionDataref.current,
        [name]: Number(e.target.value),
        dueAmount: dueAmount - e.target.value
      }
    } else {
      setAddTransactionData({
        ...addTransactionDataref.current,
        [name]: e.target.value,
      })
      addTransactionDataref.current = {
        ...addTransactionDataref.current,
        [name]: e.target.value,
      }
    }
  }

  const handleSelect = (e, name) => {
    setAddTransactionData({
      ...addTransactionDataref.current,
      [name]: e.target.value,
    })
    addTransactionDataref.current = {
      ...addTransactionDataref.current,
      [name]: e.target.value,
    }
  }

  const handleSubmit = async () => {
    if (addTransactionDataref.current.paidAmount !== null && addTransactionDataref.current.paymentMode !== null && addTransactionDataref.current.assignee !== null) {
      try {
        const result = await api.post("/payment/transaction/item/save", {
          paymentTransactionItemId: 0,
          paymentTransactionId: patient.paymentTransactionId,
          paidAmount: addTransactionDataref.current.paidAmount,
          assignee: addTransactionDataref.current.assignee,
          paymentStatus: addTransactionDataref.current.dueAmount === 0 ? 'clear' : 'due',
          paymentMode: addTransactionDataref.current.paymentMode,
          dueAmount: addTransactionDataref.current.dueAmount
        });
        if (result.data.status) {
          toast.success("Transaction Saved Successfully!");
          setAddTransactionData(tempAddTrans)
          addTransactionDataref.current = tempAddTrans
          getTransactionHistory(param.id);
          setOpenNewCase(false);
        } else {
          toast.error(result.data.message);
        }
      } catch (err) {
        console.log("err", err);
        toast.error("Something went wrong!");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Something went wrong!");
    }
  }

  const handleCancel = () => {
    setAddTransactionData(tempAddTrans)
    addTransactionDataref.current = tempAddTrans
    setOpenNewCase(false);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      w={"100%"}
      h={"100vh"}
      bg={"white"}
    >
      <Box as="header">
        <Topbar title="Transaction" />
      </Box>

      <Box
        m={"0 16px 16px"}
        pt={"16px"}
        borderRadius="8px 8px 0px 0px"
        boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
        bg={"white"}
      >
        <PatientStaticInfo patient={patient?.patientVo} />
        <Box p={5} boxShadow="md" bg="white" borderTop="1px solid #E8E8E8">
          <VStack spacing={4} align="stretch">
            <HStack justify="space-between">
              <Flex
                alignItems={"center"}
                gap={"16px"}
                cursor={"pointer"}
                onClick={() => setShowFull((prev) => !prev)}
              >
                <Text fontSize="20px" fontWeight="600" color={"black"}>
                  Transaction's History
                </Text>
              </Flex>
              {dueAmount > 0 && (
                <HStack alignItems={"center"}>
                  <Button
                    color={"black"}
                    variant="outline"
                    border={"none"}
                    fontSize={"16px"}
                    fontWeight={500}
                    isDisabled={isAddPermission === true ? false : true}
                    onClick={handleNewCase}
                  >
                    <PlusSquareIcon /> <Text ml={3}></Text>
                  </Button>
                </HStack>
              )}
            </HStack>
            {openNewCase && (
              <table className="add-transaction-table">
                <tr>
                  <th style={{ width: '10%' }}>Transaction no.</th>
                  <th style={{ width: '25%' }}>Date</th>
                  <th style={{ width: '10%' }}>Paid</th>
                  <th style={{ width: '20%' }}>Due</th>
                  <th style={{ width: '10%' }}>Pay Mode</th>
                  <th style={{ width: '25%' }}>Assignee</th>
                </tr>
                <tr className="add-transaction-data">
                  <td>{patient?.paymentTransactionItems?.length + 1}</td>
                  <td>
                    <Text>{moment(startDate).format("DD-MM-yyyy")}</Text>
                  </td>
                  <td>
                    <Input
                      name="paid"
                      placeholder="-"
                      alignContent={'center'}
                      alignItems={'center'}
                      value={addTransactionDataref.current?.paidAmount ? addTransactionDataref.current?.paidAmount : ""}
                      border={'white'}
                      max={addTransactionDataref.current?.dueAmount}
                      onChange={(e) => {
                        handleInput(e, 'paidAmount');
                      }}
                      type="number"
                    />
                  </td>
                  <td>
                    <Text>{addTransactionDataref.current?.dueAmount}</Text>
                  </td>
                  <td>
                    <Select
                      w={"100%"}
                      name="paymode"
                      color={"#747474"}
                      border={'white'}
                      placeholder="-"
                      onChange={(e) => {
                        handleSelect(e, 'paymentMode');
                      }}
                    >
                      <option value="online"><OnlineIcon label={"Online"} /></option>
                      <option value="cash"><CashIcon label={"Cash"} /></option>
                    </Select>
                  </td>
                  <td>
                    <div>
                      <Input
                        name="assignee"
                        placeholder="-"
                        border={'white'}
                        onChange={(e) => {
                          handleInput(e, "assignee");
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRadius: '0px 0px 0px 10px' }}></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{
                    textAlign: 'end'
                  }}>
                    <Button
                      alignSelf={"end"}
                      w={"120px"}
                      h={"36px"}
                      mr={"10px"}
                      background={"white"}
                      color={"black"}
                      _hover={{
                        opacity: 0.9,
                      }}
                      border={"1px solid black"}
                      style={{ verticalAlign: "middle" }}
                      fontSize={"16px"}
                      fontWeight={700}
                      fontFamily={"'Open Sans'"}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      alignSelf={"end"}
                      w={"120px"}
                      h={"36px"}
                      mr={"10px"}
                      background={"#4C8BF5"}
                      color={"white"}
                      _hover={{
                        opacity: 0.9,
                      }}
                      style={{ verticalAlign: "middle" }}
                      fontSize={"16px"}
                      fontWeight={700}
                      fontFamily={"'Open Sans'"}
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </td>
                </tr>
              </table>
            )}
            <table className="transaction-table">
              <tr>
                <th style={{ width: '2px' }}></th>
                <th>Date</th>
                <th>Treatment</th>
                <th>Total Bill</th>
                <th>Paid</th>
                <th>Due</th>
                <th>Status</th>
                <th style={{ width: '2px' }}></th>
              </tr>
              <tr>
                <td></td>
                <td>{moment(patient?.createdOn).format("DD-MM-yyyy")}</td>
                <td>
                  <div className="treatment-list-table-div" dangerouslySetInnerHTML={{ __html: patient?.summaryVo?.treatment }} />
                </td>
                <td>{patient?.totalFees}/-</td>
                <td>{patient?.paidAmount}/-</td>
                <td>{dueAmount}/-</td>
                <td>
                  <Text>{dueAmount === 0 ? 'Clear' : 'Due'}</Text>
                </td>
                <td>
                  {patient?.paymentTransactionItems?.length > 0 && (
                    <div>
                      {showFull ? (
                        <MdExpandLess
                          size={30}
                          onClick={() => setShowFull((prev) => !prev)}
                        />
                      ) : (
                        <MdExpandMore
                          size={30}
                          onClick={() => setShowFull((prev) => !prev)}
                        />
                      )}
                    </div>
                  )}
                </td>
              </tr>
              {showFull && (
                // handleSubTransactionTable()
                <>
                  <tr>
                    <td></td>
                    <th
                      style={{
                        backgroundColor: 'white',
                        borderTop: '1px solid #4C8BF5',
                        borderLeft: '1px solid #4C8BF5',
                        borderRadius: '10px 0px 0px 0px'
                      }}>
                      Transaction no.
                    </th>
                    <th
                      style={{
                        backgroundColor: 'white',
                        borderTop: '1px solid #4C8BF5'
                      }}>
                      Date
                    </th>
                    <th
                      style={{
                        backgroundColor: 'white',
                        borderTop: '1px solid #4C8BF5'
                      }}>
                      Paid
                    </th>
                    <th
                      style={{
                        backgroundColor: 'white',
                        borderTop: '1px solid #4C8BF5'
                      }}>
                      Due
                    </th>
                    <th
                      style={{
                        backgroundColor: 'white',
                        borderTop: '1px solid #4C8BF5'
                      }}>
                      Pay Mode
                    </th>
                    <th
                      style={{
                        backgroundColor: 'white',
                        borderTop: '1px solid #4C8BF5',
                        borderRight: '1px solid #4C8BF5',
                        borderRadius: '0px 10px 0px 0px'
                      }}>
                      Assignee
                    </th>
                  </tr>
                  {
                    patient?.paymentTransactionItems?.map((item, i) => {
                      return <tr className="sub-transaction-histrory-table">
                        <td></td>
                        <td style={{ backgroundColor: 'white', borderBottom: '1px solid #4C8BF5', borderLeft: '1px solid #4C8BF5', borderRadius: i === patient?.paymentTransactionItems.length -1 ? '0px 0px 0px 10px' : '' }}>{item.paymentTransactionItemNo}</td>
                        <td style={{ backgroundColor: 'white', borderBottom: '1px solid #4C8BF5' }}>{moment(item?.createdOn).format("DD-MM-yyyy")}</td>
                        <td style={{ backgroundColor: 'white', borderBottom: '1px solid #4C8BF5' }}>{item.paidAmount}/-</td>
                        <td style={{ backgroundColor: 'white', borderBottom: '1px solid #4C8BF5' }}>{item.dueAmount}/-</td>
                        <td style={{ backgroundColor: 'white', borderBottom: '1px solid #4C8BF5' }}>{item.paymentMode === 'online' ? <OnlineIcon label={"Online"} /> : <CashIcon label={"Cash"} />}</td>
                        <td style={{ backgroundColor: 'white', borderBottom: '1px solid #4C8BF5', borderRight: '1px solid #4C8BF5', borderRadius: i === patient?.paymentTransactionItems.length -1 ? '0px 0px 10px 0px' : '' }}>{item.responsiblePerson}</td>
                      </tr>
                    })
                  }
                </>
              )}
            </table>
          </VStack>
        </Box>
      </Box>
      <Box p={5} mb={10}></Box>
    </Box >
  );
}

export default TransactionView;
