import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Input,
  Text,
  Textarea,
  VStack,
  HStack,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Flex,
  Image,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import ArrowUpIcon from "../../../components/icons/ArrowUpIcon";
import EditIcon from "../../../components/icons/EditIcon";
import PlusSquareIcon from "../../../components/icons/PlusSquareIcon";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AddIcon, ArrowDownIcon, DeleteIcon } from "@chakra-ui/icons";
import ToothMappingSingle from "./ToothMappingSingle";
import api from "../../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { generateToothChart } from "../../../utils/toothMapping";
import {
  MdOutlineArrowDropDownCircle,
  MdExpandLess,
  MdExpandMore,
} from "react-icons/md";

import "../../../global/css/patient-casepaper.css";
import { handlePermissionValid } from "../../../permission/permission";

const modules = {
  toolbar: [
    [{ list: "ordered" }, { list: "bullet" }],
    // ['bold', 'italic', 'underline'],
    // ['link'],
    // [{ 'header': [1, 2, false] }],
    // [{ 'align': [] }],
    ["clean"],
  ],
};

let allToothMappingTypes = [
  "missing",
  "roots",
  "fill",
  "fcc",
  "bridge",
  "implant",
  "rct",
  "caries",
];

const PatientCasePaper = ({
  patientCase,
  patientId,
  index,
  addNewCaseData
}) => {
  const navigate = useNavigate();
  const [showFull, setShowFull] = useState(false);

  const [isAphla, setIsAphla] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedMissing, setSelectedMissing] = useState([]);
  const [treatmentAdvice, setTreatmentAdvice] = useState("");
  const [treatmentAdviceToDo, setTreatmentAdviceToDo] = useState("");
  const [showAdditionalNotes, setShowAdditionalNotes] = useState(false);
  const [currentCaseData, setCurrentCaseData] = useState(patientCase);
  // SET LAB STATUS COLOR DYANMIC :: START
  const [statusBackgroundColor, setStatusBackgroundColor] = useState("#F8FAFF");
  // SET LAB STATUS COLOR DYANMIC :: ENDS
  const permissionRef = useRef({
    isEditPermission: false,
    isAddPermission: false
  });

  useEffect(() => {
    let add = handlePermissionValid('/patients', "Patients", 'INSERT')
    let edit = handlePermissionValid('/patients', "Patients", 'EDIT')
    console.log("called");
    permissionRef.current = {
      isEditPermission: edit,
      isAddPermission: add
    }
  }, [])

  useEffect(() => {
    if (index === 0) {
      setShowFull(true);
    }
    if (patientCase.casePaperId === 0) {
      setIsEditing(true);
    }
    setCurrentCaseData({ ...patientCase });
    let toothMapData = [];
    patientCase?.toothChart?.forEach((s) => {
      if (s.lowerRowLeft) {
        s.lowerRowLeft.split(",").forEach((t) => {
          toothMapData.push(`${s.toothChartType}-lowerRowLeft-${t}`);
        });
      }

      if (s.lowerRowRight) {
        s.lowerRowRight.split(",").forEach((t) => {
          toothMapData.push(`${s.toothChartType}-lowerRowRight-${t}`);
        });
      }

      if (s.upperRowLeft) {
        s.upperRowLeft.split(",").forEach((t) => {
          toothMapData.push(`${s.toothChartType}-upperRowLeft-${t}`);
        });
      }

      if (s.upperRowRight) {
        s.upperRowRight.split(",").forEach((t) => {
          toothMapData.push(`${s.toothChartType}-upperRowRight-${t}`);
        });
      }
    });
    setSelectedMissing(toothMapData);
  }, [patientCase]);
  console.log("currentCaseData", currentCaseData);

  console.log("selectedMissing ", selectedMissing);
  const addOrRemoveSelectedMissing = (
    isDoubleClick,
    selected,
    selected2 = null
  ) => {
    console.log("Comming..");

    setSelectedMissing((prev) => {
      if (prev.length === 0) {
        if (isDoubleClick) {
          if (selected2) {
            return [selected, selected2];
          }
          return [selected];
        }
        return [selected];
      } else {
        if (isDoubleClick) {
          let alreadyAdded = prev.find((s) => s === selected);
          let alreadyAdded2;

          if (selected2) {
            alreadyAdded2 = prev.find((s) => s === selected2);
          }

          if (!alreadyAdded && !alreadyAdded2 && selected2) {
            return [...prev, selected, selected2];
          }

          if (alreadyAdded && alreadyAdded2) {
            return prev.filter((s) => !(s === selected || s === selected2));
          } else {
            if (alreadyAdded2) {
              return [...prev, selected];
            } else if (alreadyAdded && selected2) {
              return [...prev, selected2];
            } else {
              return prev.filter((s) => s !== selected);
            }
          }
        } else {
          let alreadyAdded = prev.find((s) => s === selected);

          if (alreadyAdded) {
            return prev.filter((s) => {
              return s !== selected;
            });
          } else {
            return [...prev, selected];
          }
        }
      }
    });
  };

  const addContact = () => {
    setCurrentCaseData((prev) => {
      return {
        ...prev,
        labWorks: [
          ...prev.labWorks,
          { id: prev.labWorks.length + 1, labWork: "", labWorkStatus: "" },
        ],
      };
    });
  };

  const removeContact = (id) => {
    setCurrentCaseData((prev) => {
      return {
        ...prev,
        labWorks: prev.labWorks.filter((s) => s.id !== id),
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCaseData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLabWorkChange = (e, id) => {
    const { name, value } = e.target;
    console.log("VALUEEEEE : ", value);
    const updatedLabWork = currentCaseData?.labWorks.map((labwork, i) => {
      return id === labwork.id ? { ...labwork, [name]: value } : labwork;
    });

    setCurrentCaseData((prev) => ({ ...prev, labWorks: updatedLabWork }));
  };

  const onSubmit = async () => {
    const toothChart = generateToothChart(
      selectedMissing,
      currentCaseData?.casePaperId ? currentCaseData?.casePaperId : 0,
      currentCaseData?.toothChart?.length > 0
        ? [
            currentCaseData?.toothChart?.[0]?.toothChartId,
            currentCaseData?.toothChart?.[1]?.toothChartId,
            currentCaseData?.toothChart?.[2]?.toothChartId,
            currentCaseData?.toothChart?.[3]?.toothChartId,
            currentCaseData?.toothChart?.[4]?.toothChartId,
            currentCaseData?.toothChart?.[5]?.toothChartId,
            currentCaseData?.toothChart?.[6]?.toothChartId,
            currentCaseData?.toothChart?.[7]?.toothChartId,
            currentCaseData?.toothChart?.[8]?.toothChartId,
            currentCaseData?.toothChart?.[9]?.toothChartId,
          ]
    : 0,
      0,
      0,
      0,
      0,
      0,
      0,
      0
    );
    try {
      const result = await api.post(`/patient/${patientId}/casepaper/save`, {
        ...currentCaseData,
        labWorks: currentCaseData.labWorks.map((s) => ({
          labWork: s.labWork,
          labWorkStatus: s.labWorkStatus,
          labWorkId: s?.labWorkId || 0,
          patientId: patientId,
        })),
        labWork:
          currentCaseData?.labWorks?.length > 0
            ? currentCaseData?.labWorks[0].labWork
            : "",
        toothChart,
      });
      console.log("result ", result.data);
      if (result.data.status) {
        toast.success("Patient Case Added!");
        navigate(`/patients/${patientId}/case`);
        setShowFull(false);
      } else {
        console.log("result.message ", result.data.message);
        toast.error(result.data.message);
      }
    } catch (err) {
      console.log("err", err);
      toast.error("Something went wrong!");
    } finally {
      // setLoading(false);
    }
  };
  return (
    <Box p={0} boxShadow="md" bg="white" borderTop="1px solid #E8E8E8">
      <VStack spacing={4} align="stretch">
        <Box px={4} py={2}>
          <HStack justify="space-between">
            <Flex
              alignItems={"center"}
              gap={"16px"}
              cursor={"pointer"}
              onClick={() => setShowFull((prev) => !prev)}
            >
              <Text fontSize="20px" fontWeight="600" color={"black"}>
                Case Paper
              </Text>
              {showFull ? (
                <MdExpandLess size={30} />
              ) : (
                <MdExpandMore size={30} />
              )}
            </Flex>

            <HStack alignItems={"center"}>
              <Text color={"#3F3F46"}>
                {patientCase?.createdOn
                  ? moment(patientCase?.createdOn).format("DD-MM-yyyy")
                  : moment().format("DD-MM-yyyy")}
              </Text>
              <Button
                variant={"ghost"}
                isDisabled={permissionRef.current.isAddPermission ? false : true}
                onClick={() => setIsEditing((prev) => !prev)}
              >
                <Box mr={"8px"}>
                  <EditIcon />
                </Box>
                <Text color={"#3F3F46"} fontWeight={400} fontSize={"16px"}>
                  {isEditing ? "Save" : "Edit"}
                </Text>
              </Button>
              {index === 0 && (
                <Button
                  color={"black"}
                  variant="outline"
                  border={"none"}
                  fontSize={"16px"}
                  fontWeight={500}
                  isDisabled={permissionRef.current.isAddPermission ? false : true}
                  onClick={addNewCaseData}
                >
                  <PlusSquareIcon /> <Text ml={3}>Add new case paper</Text>
                </Button>
              )}
            </HStack>
          </HStack>
        </Box>

        {showFull && (
          <>
            <Box px={4}>
              {/* <FormControl mb={7} className="family-dr-div">
                <FormLabel fontSize={"14px"}>
                  <strong>Family Dr.</strong>
                </FormLabel>
                <Input
                  border={"1.6px solid #D2D2D2"}
                  disabled={!isEditing}
                  _disabled={{
                    color: "black",
                    border: "none",
                    borderRadius: "none",
                    borderBottom: "1px solid black",
                  }}
                  style={{ backgroundColor: "#F8FAFF" }}
                  onChange={handleInputChange}
                  name="familyDoctorName"
                  value={currentCaseData.familyDoctorName}
                />
              </FormControl> */}
              <SimpleGrid columns={2} spacing={2}>
                {/* fdfdfd */}
              <Box>
                  <FormControl className="examination-card">
                    <FormLabel fontSize={"14px"} p={2}>
                      Family Dr.
                    </FormLabel>
                    {/* <Input
                    border={"1.6px solid #D2D2D2"}
                    name="familyDoctorName"
                    disabled={!isEditing}
                    _disabled={{
                      color: "black",
                      border: "none",
                      borderRadius: "none",
                      borderBottom: "1px solid black",
                    }}
                    onChange={handleInputChange}
                    value={currentCaseData.familyDoctorName}
                    style={{ backgroundColor: "#F8FAFF" }}
                  /> */}
                    {isEditing ? (
                      <ReactQuill
                        style={{
                          backgroundColor: "rgba(248, 250, 255, 1)",
                          borderRadius: "5px",
                        }}
                        theme="snow"
                        modules={modules}
                        value={currentCaseData.familyDoctorName}
                        onChange={(changes) => {
                          setCurrentCaseData((prev) => ({
                            ...prev,
                            familyDoctorName: changes,
                          }));
                        }}
                      />
                    ) : (
                      <Box
                        className="examination-quill-display-box"
                        border={"1px solid #D2D2D2"}
                        borderRadius={"8px"}
                        p={"16px 40px"}
                        dangerouslySetInnerHTML={{
                          __html: currentCaseData.familyDoctorName,
                        }}
                      ></Box>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <FormControl className="examination-card">
                    <FormLabel fontSize={"14px"} p={2}>
                      C/O
                    </FormLabel>
                    {/* <Input
                    border={"1.6px solid #D2D2D2"}
                    name="complain"
                    disabled={!isEditing}
                    _disabled={{
                      color: "black",
                      border: "none",
                      borderRadius: "none",
                      borderBottom: "1px solid black",
                    }}
                    onChange={handleInputChange}
                    value={currentCaseData.complain}
                    style={{ backgroundColor: "#F8FAFF" }}
                  /> */}
                    {isEditing ? (
                      <ReactQuill
                        style={{
                          backgroundColor: "rgba(248, 250, 255, 1)",
                          borderRadius: "5px",
                        }}
                        theme="snow"
                        modules={modules}
                        value={currentCaseData.complain}
                        onChange={(changes) => {
                          setCurrentCaseData((prev) => ({
                            ...prev,
                            complain: changes,
                          }));
                        }}
                      />
                    ) : (
                      <Box
                        className="examination-quill-display-box"
                        border={"1px solid #D2D2D2"}
                        borderRadius={"8px"}
                        p={"16px 40px"}
                        dangerouslySetInnerHTML={{
                          __html: currentCaseData.complain,
                        }}
                      ></Box>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <FormControl className="examination-card">
                    <FormLabel fontSize={"14px"} p={2}>
                      H/O
                    </FormLabel>
                    {/* <Input
                    border={"1.6px solid #D2D2D2"}
                    disabled={!isEditing}
                    style={{ backgroundColor: "#F8FAFF" }}
                    _disabled={{
                      color: "black",
                      border: "none",
                      borderRadius: "none",
                      borderBottom: "1px solid black",
                    }}
                    name="historyOff"
                    onChange={handleInputChange}
                    value={currentCaseData.historyOff}
                  /> */}
                    {isEditing ? (
                      <ReactQuill
                        style={{
                          backgroundColor: "rgba(248, 250, 255, 1)",
                          borderRadius: "5px",
                        }}
                        theme="snow"
                        modules={modules}
                        value={currentCaseData.historyOff}
                        onChange={(changes) => {
                          setCurrentCaseData((prev) => ({
                            ...prev,
                            historyOff: changes,
                          }));
                        }}
                      />
                    ) : (
                      <Box
                        className="examination-quill-display-box"
                        border={"1px solid #D2D2D2"}
                        borderRadius={"8px"}
                        p={"16px 40px"}
                        dangerouslySetInnerHTML={{
                          __html: currentCaseData.historyOff,
                        }}
                      ></Box>
                    )}
                  </FormControl>
                </Box>
                <Box>
                  <FormControl className="examination-card">
                    <FormLabel fontSize={"14px"} p={2}>
                      O/E
                    </FormLabel>
                    {/* <Input border={"1.6px solid #D2D2D2"} disabled={!isEditing}
                  style={{backgroundColor: "#F8FAFF"}}
                  _disabled={{
                    color: "black",
                    border: "none",
                    borderRadius: "none",
                    borderBottom: "1px solid black"
                  }} name="onExamination" onChange={handleInputChange} value={currentCaseData.onExamination} /> */}
                    {isEditing ? (
                      <ReactQuill
                        style={{
                          backgroundColor: "rgba(248, 250, 255, 1)",
                          borderRadius: "5px",
                        }}
                        theme="snow"
                        modules={modules}
                        value={currentCaseData.onExamination}
                        onChange={(changes) => {
                          setCurrentCaseData((prev) => ({
                            ...prev,
                            onExamination: changes,
                          }));
                        }}
                      />
                    ) : (
                      <Box
                        className="examination-quill-display-box"
                        border={"1px solid #D2D2D2"}
                        borderRadius={"8px"}
                        p={"16px 40px"}
                        dangerouslySetInnerHTML={{
                          __html: currentCaseData.onExamination,
                        }}
                      ></Box>
                    )}
                  </FormControl>
                </Box>
              </SimpleGrid>
            </Box>

            {/* TOOTHCHART SECTION  :::: STARTS */}
            <Box className="tootchart-section" p={0}>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                borderBottom={"1.6px solid #3F3F46"}
              >
                <Text
                  fontSize={"20px"}
                  fontWeight={700}
                  color={"#333"}
                  px={4}
                  py={2}
                >
                  Tooth Chart
                </Text>
              </Flex>

              <Box p={4}>
                <HStack>
                  <ToothMappingSingle
                    id={"presentteeth"}
                    name={"Present teeth"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                  <ToothMappingSingle
                    id={"missing"}
                    name={"Missing"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                </HStack>
                
                <HStack>
                  <ToothMappingSingle
                    id={"roots"}
                    name={"Roots"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                  <ToothMappingSingle
                    id={"filling"}
                    name={"Filling"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                </HStack>

                <HStack>
                  <ToothMappingSingle
                    id={"fcc"}
                    name={"FCC"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                  
                  <ToothMappingSingle
                    id={"bridge"}
                    name={"Bridge"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                </HStack>

                <HStack>
                  <ToothMappingSingle
                    id={"implant"}
                    name={"Implant"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                  
                  <ToothMappingSingle
                    id={"rct"}
                    name={"RCT"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                </HStack>

                <HStack>
                  <ToothMappingSingle
                    id={"caries"}
                    name={"Caries"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                  
                  <ToothMappingSingle
                    id={"impactedretained"}
                    name={"Impacted/retained"}
                    selectedMissing={selectedMissing}
                    addOrRemoveSelectedMissing={addOrRemoveSelectedMissing}
                    isAphla={isAphla}
                  />
                </HStack>
              </Box>
            </Box>
            {/* TOOTHCHART SECTION  :::: ENDS */}

            {/* TREATMENT SECTION  :::: START */}
            <Box p={4} className="treatment-section">
              <Flex gap={"16px"} fontSize={"16px"}>
                <Box flex={1}>
                  <Text fontWeight="bold" mb={"8px"}>
                    Treatment Advice
                  </Text>
                  {isEditing ? (
                    <ReactQuill
                      style={{
                        backgroundColor: "rgba(248, 250, 255, 1)",
                        borderRadius: "5px",
                      }}
                      theme="snow"
                      modules={modules}
                      value={currentCaseData.treatmentAdvice}
                      onChange={(changes) => {
                        setCurrentCaseData((prev) => ({
                          ...prev,
                          treatmentAdvice: changes,
                        }));
                      }}
                    />
                  ) : (
                    <Box
                      border={"1px solid #D2D2D2"}
                      borderRadius={"8px"}
                      p={"16px 40px"}
                      dangerouslySetInnerHTML={{
                        __html: currentCaseData.treatmentAdvice,
                      }}
                    ></Box>
                  )}
                </Box>
                <Box flex={1}>
                  <Text fontWeight="bold" mb={"8px"}>
                    Treatment To Do
                  </Text>
                  {isEditing ? (
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      value={currentCaseData.treatmentTodo}
                      style={{
                        backgroundColor: "rgba(248, 250, 255, 1)",
                        borderRadius: "5px",
                      }}
                      onChange={(changes) => {
                        setCurrentCaseData((prev) => ({
                          ...prev,
                          treatmentTodo: changes,
                        }));
                      }}
                    />
                  ) : (
                    <Box
                      border={"1px solid #D2D2D2"}
                      borderRadius={"8px"}
                      p={"16px 40px"}
                      dangerouslySetInnerHTML={{
                        __html: currentCaseData.treatmentTodo,
                      }}
                    ></Box>
                  )}
                </Box>
              </Flex>
            </Box>
            {/* TREATMENT SECTION  :::: ENDS */}

            {/* LABWORK SECTION  :::: STARTS */}
            <Box className="labwork-section" p={4}>
              <Flex
                flexDir={"column"}
                alignItems={"left"}
                mb={"16px"}
                fontSize={"16px"}
              >
                <Flex
                  justifyContent={"space-between"}
                  w={"75%"}
                  alignItems={"left"}
                >
                  <FormLabel fontWeight="bold">Lab Work</FormLabel>
                  {isEditing && (
                    <Button
                      variant={"ghost"}
                      color={"#4C8BF5"}
                      fontWeight={500}
                      fontSize={"14px"}
                      onClick={addContact}
                      leftIcon={<PlusSquareIcon color="#4C8BF5" ml={0} />}
                    ></Button>
                  )}
                </Flex>
                {currentCaseData &&
                  currentCaseData?.labWorks?.length > 0 &&
                  currentCaseData?.labWorks?.map((details, index) => (
                    <Flex
                      w={"100%"}
                      gap={2}
                      key={details.id}
                      mb={
                        index + 1 !== currentCaseData?.labWorks.length ? 4 : ""
                      }
                    >
                      <FormControl flex={5}>
                        <Input
                          border="1.4px solid #D2D2D2"
                          name="labWork"
                          disabled={!isEditing}
                          style={{ backgroundColor: "#F8FAFF" }}
                          _disabled={{
                            color: "black",
                            border: "none",
                            borderRadius: "none",
                            borderBottom: "1px solid black",
                          }}
                          placeholder="Lab Work*"
                          onChange={(e) => {
                            handleLabWorkChange(e, details.id);
                          }}
                          value={details.labWork}
                        />
                      </FormControl>
                      {isEditing ? (
                        <FormControl flex={1.5}>
                          <Flex w={"100%"} gap={2}>
                            <Text fontSize={"14px"} fontWeight={600} m={"auto"}>
                              Status
                            </Text>
                            <Select
                              className="labwork-status-select"
                              w={"100%"}
                              border="1.4px solid #D2D2D2"
                              name="labWorkStatus"
                              disabled={!isEditing}
                              icon={<MdOutlineArrowDropDownCircle />}
                              _disabled={{
                                color: "black",
                                border: "none",
                                borderRadius: "none",
                                borderBottom: "1px solid black",
                              }}
                              color={"#747474"}
                              placeholder="Lab Work Status"
                              onChange={(e) => {
                                handleLabWorkChange(e, details.id);
                              }}
                              value={[details.labWorkStatus]}
                              style={{
                                backgroundColor: statusBackgroundColor,
                                marginRight: "14%",
                              }}
                            >
                              <option value="incomplete">Incomplete</option>
                              <option value="complete">Complete</option>
                            </Select>
                          </Flex>
                        </FormControl>
                      ) : (
                        <Flex gap={"8px"} alignItems={"center"} mr={5}>
                          <Text fontSize={"14px"} fontWeight={600}>
                            Status
                          </Text>
                          <Flex
                            alignItems={"center"}
                            justifyContent={"center"}
                            gap={"8px"}
                            w={"100px"}
                            h={"29px"}
                            borderRadius="40px"
                            background={
                              details.labWorkStatus === "incomplete"
                                ? "#FEF9C3"
                                : "#DCFCE7"
                            }
                          >
                            <Text
                              color={
                                details.labWorkStatus === "incomplete"
                                  ? "#713F12"
                                  : "#14532D"
                              }
                              fontFamily="Open Sans"
                              fontSize="10px"
                              fontWeight={900}
                            >
                              {details.labWorkStatus === "incomplete"
                                ? "Incomplete"
                                : "Complete"}
                            </Text>
                            <Image
                              src={`${process.env.PUBLIC_URL}/images/DownArrowIcon.png`}
                              alt="downarrow"
                            />
                          </Flex>
                        </Flex>
                      )}
                      {isEditing && (
                        <Flex>
                          {index !== 0 ? (
                            <IconButton
                              aria-label="Remove contact"
                              icon={<DeleteIcon />}
                              onClick={() => removeContact(details.id)}
                              variant="outline"
                              alignSelf="center"
                            />
                          ) : (
                            <Box>
                              <IconButton alignSelf="center" />
                            </Box>
                          )}
                        </Flex>
                      )}
                    </Flex>
                  ))}
              </Flex>
            </Box>
            {/* LABWORK SECTION  :::: ENDS */}

            <Box className="additional-notes-section" px={4}>
              {showAdditionalNotes && (
                <Flex
                  flexDir={"column"}
                  p={"8px"}
                  gap={"16px"}
                  fontSize={"16px"}
                >
                  <Box w={"50%"}>
                    <FormLabel fontWeight="bold" mb={"8px"}>
                      Additional Notes
                    </FormLabel>
                    <Input
                      border={"1.6px solid #D2D2D2"}
                      name="additionalNotes"
                      onChange={handleInputChange}
                      value={currentCaseData.additionalNotes}
                    />
                  </Box>
                </Flex>
              )}
            </Box>

            <Box
              px={4}
              py={6}
              mb={2}
              className="additional-notes-footer-buttons"
            >
              <HStack justify="space-between">
                <Button
                  fontWeight={500}
                  flex={1}
                  color={"#4C8BF5"}
                  variant={"outline"}
                  borderColor={"#4C8BF5"}
                  fontSize={"16px"}
                  isDisabled={permissionRef.current.isAddPermission ? false : true}
                  onClick={() => setShowAdditionalNotes((prev) => !prev)}
                >
                  Add Additional Notes
                </Button>
                <Button
                  flex={1}
                  color={"white"}
                  background={"#4C8BF5"}
                  isDisabled={permissionRef.current.isAddPermission ? false : true}
                  onClick={onSubmit}
                >
                  Save
                </Button>
              </HStack>
            </Box>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default PatientCasePaper;
