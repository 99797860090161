import { Grid, Input, Select } from "react-spreadsheet-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Box, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import api from "../../../api";
import { toast } from "react-toastify";
import ArrowUpIcon from "../../../components/icons/ArrowUpIcon";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import ReactDataSheet from 'react-datasheet'; 
import 'react-datasheet/lib/react-datasheet.css';
import '../../../global/css/patient-summary-list.css';
import CustomDatePicker from "../../../components/CustomDatePicker";
import { CalendarIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import LabWorkStatusBadge from "../../../components/LabWorkStatusBadge";
import { handlePermissionValid } from "../../../permission/permission";

const PatientSummary2 = ({
  patientSummary,
  id,
  fetchDataPatientSummaryInfo,
}) => {
  const [rows, setRows] = useState([]);

  const permissionRef = useRef({
    isEditPermission: false,
    isAddPermission: false
  });

  useEffect(() => {
    let add = handlePermissionValid('/patients', "Patients", 'INSERT')
    let edit = handlePermissionValid('/patients', "Patients", 'EDIT')
    permissionRef.current = {
      isEditPermission: edit,
      isAddPermission: add
    }
  }, [])

  useEffect(() => {
    setRows(patientSummary);
  }, [patientSummary]);

  const modules = {
    toolbar: [
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['clean']
    ],
  };

  useEffect(() => {
    fetchDataPatientSummaryInfo(id);
  }, []);
    const TextAreaEditor = ({ value, onChange, onKeyDown }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const handleChange = (html) => {
      onChange(html);
    };

    return (
      <ReactQuill
        style={{ backgroundColor: 'rgba(248, 250, 255, 1)', borderRadius: '5px', width: '100%', height: '100% !important' }}
        theme="snow"
        modules={modules}
        value={value}
        onChange={handleChange}
      />
    );
  };

  const DateEditor = ({ value, onChange, onKeyDown }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, []);

    const handleChange = useCallback((e) => {
      onChange(e.target.value);
    }, [onChange]);

    return (
      <input
        ref={inputRef}
        type="date"
        className="data-editor"
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
    );
  };

  const BadgeViewer = ({ value }) => {
    console.log("VALUEEEEEEEEE >>>>> " , (value == 'completed') || (value == 'clear'))
    const backgroundColor = (((value == 'completed') || (value == 'clear')) ? ('#DCFCE7') : ('#FEF9C3'));
    const style = {
      padding: '5px',
      background: backgroundColor,
      borderRadius: '40px',
      width: '84%',
      textAlign: 'center',
      margin: 'auto'
    };
    return (
      <Box px={2}>
        <LabWorkStatusBadge status={value} />
      </Box>
      
    );
  }

  const FeesViewer = ({ value }) => {
    return (
      <p style={{ textAlign: 'center' }}>{((value) ? (value) : 0)} /-</p>
    );
  }

  const DateViewer = ({ value }) => {
    return (
      <div className="date-picker-custom-summart-content">
        <label>{moment(value).format("DD-MM-yyyy")}</label>
        <CalendarIcon style={{ margin: 'auto' }} />
      </div>
    );
  }

  const HtmlDataViewer = ({ value }) => {
    return (
      <Box style={{ padding: '0px 5% 0px 15%', display: 'flex', justifyContent: 'center' ,textAlign:'left'}}>
        <div dangerouslySetInnerHTML={{ __html: value }} />
      </Box>
    );
  }

  const defaultSummaryRow = [
    { value: 0, disableEvents: true, className: 'd-none' },
    { value: (new Date()), dataEditor: DateEditor, valueViewer: DateViewer },
    { value: "", dataEditor: TextAreaEditor, valueViewer: HtmlDataViewer },
    { value: "", dataEditor: TextAreaEditor, valueViewer: HtmlDataViewer },
    { value: "" },
    { value: '0', valueViewer: FeesViewer },
    { value: '0', readOnly: true, valueViewer: FeesViewer },
    { value: 'Due', readOnly: true, valueViewer: BadgeViewer },
  ];

  const [grid, setGrid] = useState([
    [
      { value: "", readOnly: true, disableEvents: true, className: 'd-none', },
      { value: "Date", readOnly: true },
      { value: "Treatment", readOnly: true },
      { value: "Medicine", readOnly: true },
      { value: "Doctor", readOnly: true },
      { value: "Fees", readOnly: true },
      { value: "Paid", readOnly: true },
      { value: "Status", readOnly: true },
    ],
    // defaultSummaryRow,
  ]);

  const getPatientSummaryDataIntoDataSheet = useCallback(() => {
    if (patientSummary && patientSummary.length > 0) {
      const dataArray = patientSummary.map(patientSummaryVo => [
        { value: patientSummaryVo.patientSummaryId, disableEvents: true, className: 'd-none', readOnly: (permissionRef.current.isAddPermission || permissionRef.current.isEditPermission) ? false : true },
        { value: patientSummaryVo.summaryDate, dataEditor: DateEditor, valueViewer: DateViewer, readOnly: (permissionRef.current.isAddPermission || permissionRef.current.isEditPermission) ? false : true },
        { value: patientSummaryVo.treatment, dataEditor: TextAreaEditor, valueViewer: HtmlDataViewer, readOnly: (permissionRef.current.isAddPermission || permissionRef.current.isEditPermission) ? false : true },
        { value: patientSummaryVo.medicine, dataEditor: TextAreaEditor, valueViewer: HtmlDataViewer, readOnly: (permissionRef.current.isAddPermission || permissionRef.current.isEditPermission) ? false : true },
        { value: patientSummaryVo.doctorName,className: 'text-center', readOnly: (permissionRef.current.isAddPermission || permissionRef.current.isEditPermission) ? false : true },
        { value: patientSummaryVo.dueAmount, valueViewer: FeesViewer, readOnly: (permissionRef.current.isAddPermission || permissionRef.current.isEditPermission) ? false : true },
        { value: patientSummaryVo.paidAmount, valueViewer: FeesViewer,readOnly: true },
        { value: patientSummaryVo.paymentStatus, readOnly: true, valueViewer: BadgeViewer },
      ]);

      setGrid(prevGrid => [prevGrid[0], ...dataArray]);
    }
  }, [patientSummary]);

  useEffect(() => {
    getPatientSummaryDataIntoDataSheet();
  }, [patientSummary, getPatientSummaryDataIntoDataSheet]);


  const validateRow = (rowData) => {
    // Check if specific columns are filled
    const requiredColumns = [1, 2, 3, 4, 5]; // Indices for Date, Treatment, Medicine, Doctor, Fees
    return requiredColumns.every(colIndex => (rowData[colIndex].value !== '') && (rowData[5].value != 0));
  };

  const formatRowData = (rowData) => {
    // Extract and format row data for API
    return {
      patientSummaryId: rowData[0].value,
      summaryDate: moment(rowData[1].value).format("YYYY-MM-DD"),
      treatment: rowData[2].value, // Directly using the value
      medicine: rowData[3].value,  // Directly using the value
      doctorId: 0, // Hardcoded as per requirement
      patientId: 10, // Should be dynamic or passed as prop
      doctorName: rowData[4].value, // Set from rowData[4].value
      dueAmount: parseFloat(rowData[5].value), // Ensure it's a number
      paymentStatus: "incompleted",
      additionalNotes: "" // Should be dynamic
    };
  };

  
  const onSave = async (rowData) => {
    if (!validateRow(rowData)) {
      toast.error('Columns 1, 2, 3, 4, 5 cannot be blank.');
      return;
    }

    try {
      const formattedData = formatRowData(rowData);
      console.log("Formatted Data for API: ", formattedData);
      // await saveUpdatedData(formattedData);
      try {
        const result = await api.post(`/patient/${id}/summary/save`, formattedData);
        console.log("result ", result.data);
        if (result.data.status) {
          toast.success("Patient Case Added!");
          console.log('Data saved successfully');
          fetchDataPatientSummaryInfo(id);    
          getPatientSummaryDataIntoDataSheet()   
          setGrid(prevGrid => [...prevGrid, defaultSummaryRow]);
        } else {
          console.log("result.message ", result.data.message);
          toast.error(result.data.message);
        }
      } catch (err) {
        console.log("err", err);
        toast.error("Something went wrong!");
      }
      
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const onCellsChanged = useCallback((changes) => {
    console.log("CHANGES", changes);

    setGrid((prevGrid) => {
      const newGrid = prevGrid.map((row, rowIndex) => {
        if (changes.some(change => change.row === rowIndex)) {
          const updatedRow = row.map((cell, colIndex) => {
            const change = changes.find(c => c.row === rowIndex && c.col === colIndex);
            return change ? { ...cell, value: change.value } : cell;
          });

          // Trigger save if necessary columns are filled
          if (validateRow(updatedRow) && (permissionRef.current.isAddPermission || permissionRef.current.isEditPermission)) {
            onSave(updatedRow);
          }

          return updatedRow;
        }
        return row;
      });

      return newGrid;
    });
  }, []);
  
  return (
    <Box boxShadow="md" bg="white" borderTop="1px solid #E8E8E8">
      <Accordion allowToggle allowMultiple>
        <AccordionItem>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <AccordionButton flex={3}>
              <Box as="span" textAlign="left" py={2}>
                <Text fontSize="20px" fontWeight="600" color={"black"}>
                  Summary
                </Text>
              </Box>
              <AccordionIcon mx={5} style={{ fontSize: "30px" }} />
            </AccordionButton>
            <Box as="span" textAlign="left"></Box>
          </div>
         <AccordionPanel pb={4}>
            <ReactDataSheet
              background={'#F8FAFF'}
              data={grid}
              valueRenderer={(cell) => cell.value}
              onContextMenu={(e, cell, i, j) => { if (cell.readOnly) e.preventDefault(); }}
              onCellsChanged={onCellsChanged}
              
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default PatientSummary2;