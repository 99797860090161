export function handlePermissionValid(accessURL, title, opt) {

    let permission = JSON.parse(localStorage.getItem('user_permission'))
    // console.log("permission>>>", permission, accessURL, title, opt);

    let response = false
    permission.map((item, i) => {
        if (item.menuURL === accessURL || item.title === title) {
            item.subMenu.map((subItem) => {
                if (subItem.menuURL === accessURL) {
                    subItem.action.map((act) => {
                        if (act.actionName === opt) {
                            response = true
                        }
                    })
                }
            })
        }
    })

    return response
}
