import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    HStack,
    Textarea,
    Box,
    FormErrorMessage,
    InputLeftAddon,
    InputGroup,
    InputLeftElement,
    Switch,
} from "@chakra-ui/react";
import TimePicker from "react-time-picker";
import { useEffect, useState } from "react";
import IdentificationCard from "../../components/icons/IdentificationCard";
import At from "../../components/icons/At";
import Phone from "../../components/icons/Phone";
import Certificate from "../../components/icons/Certificate";
import EyeIcon from "../../components/icons/EyeIcon";

const AddUserModal = ({ isOpen, onClose, handleAdd, roleList, isEdit, selectedData }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [userType, setUserType] = useState("");
    const [userRole, setUserRole] = useState("");
    const [degree, setDegree] = useState("");
    const [password, setPassword] = useState("");
    const [isAccess, setIsAccess] = useState(false);
    const [error, setError] = useState({
        name: false,
        email: false,
        contactNo: false,
        userType: false,
        degree: false
    })

    useEffect(() => {
        if (isEdit) {
            setName(selectedData.name)
            setEmail(selectedData.email)
            setContactNo(selectedData.contactNo)
            setUserType(selectedData.userType)
            setUserRole(selectedData.roles[0].userRoleId)
            setDegree(selectedData.degreeCertificate)
        }
    }, [isEdit])

    const validate = () => {
        let isValid = [!name ? true : false,
        !email ? true : false,
        !contactNo ? true : false,
        !userType ? true : false,
        !degree ? true : false];
        setError({
            name: !name ? true : false,
            email: !email ? true : false,
            contactNo: !contactNo ? true : false,
            userType: !userType ? true : false,
            degree: !degree ? true : false
        })
        isValid = isValid.includes(true) ? false : true
        return isValid;
    };

    const handleAddData = () => {
        let formValid = validate();
        if (!formValid) {
            return;
        }
        handleAdd({
            name: name,
            email: email,
            contactNo: contactNo,
            userType: userType,
            degree: degree,
            userRoleId: userRole,
            password: password,
        })
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add User</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={4} isInvalid={error?.name}>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <IdentificationCard />
                            </InputLeftElement>
                            <Input placeholder={`add user Name`} border={'1px solid rgba(116, 116, 116, 1)'} borderRadius={'8px'} value={name} onChange={(e) => setName(e.target.value)} />
                        </InputGroup>
                        {error.name && (
                            <FormErrorMessage fontSize={14} color={"#F04438"}>Please Add UserName</FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl mb={4} isInvalid={error?.email}>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <At />
                            </InputLeftElement>
                            <Input placeholder="add user’s email id" border={'1px solid rgba(116, 116, 116, 1)'} borderRadius={'8px'} value={email} onChange={(e) => setEmail(e.target.value)} />
                        </InputGroup>
                        {error.email && (
                            <FormErrorMessage fontSize={14} color={"#F04438"}>Please Add Email</FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl mb={4} isInvalid={error?.contactNo}>

                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <Phone />
                            </InputLeftElement>
                            <Input placeholder="add user’s phone mobile number" border={'1px solid rgba(116, 116, 116, 1)'} borderRadius={'8px'} value={contactNo} onChange={(e) => setContactNo(e.target.value)} />
                        </InputGroup>
                        {error.contactNo && (
                            <FormErrorMessage fontSize={14} color={"#F04438"}>Please Add Phone Mobile Number</FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl mb={4} display={'flex'} alignItems={'center'} isInvalid={error?.userType}>
                        <FormLabel w={'40%'} ml={5} fontSize={'16px'} fontWeight={700} fontFamily={'Oxygen'}>Assign Type</FormLabel>
                        <div style={{ width: '100%' }}>
                            <Select placeholder="ex. Staff, Doctor, Admin" border={'1px solid rgba(116, 116, 116, 1)'} borderRadius={'8px'} value={userType} onChange={(e) => setUserType(e.target.value)}>
                                {/* <option value="admin">Admin</option> */}
                                <option value="doctor">Doctor</option>
                                <option value="staff">Staff</option>
                            </Select>
                            {error.userType && (
                                <FormErrorMessage fontSize={14} color={"#F04438"}>Please Select Type</FormErrorMessage>
                            )}
                        </div>
                    </FormControl>
                    <FormControl mb={4} display={'flex'} alignItems={'center'}>
                        <FormLabel w={'40%'} ml={5} fontSize={'16px'} fontWeight={700} fontFamily={'Oxygen'}>Assign Role</FormLabel>
                        <div style={{ width: '100%' }}>
                            <Select placeholder="select role" border={'1px solid rgba(116, 116, 116, 1)'} borderRadius={'8px'} value={userRole} onChange={(e) => setUserRole(e.target.value)}>
                                {/* <option value="admin">Admin</option> */}
                                {roleList.map((item) => {
                                    return <option value={item.userRoleId}>{item.userRoleName}</option>
                                })}
                            </Select>
                        </div>
                    </FormControl>
                    <FormControl mb={4} isInvalid={error?.degree}>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                <Certificate />
                            </InputLeftElement>
                            <Input placeholder="add degree or certificates" border={'1px solid rgba(116, 116, 116, 1)'} borderRadius={'8px'} value={degree} onChange={(e) => setDegree(e.target.value)} />
                        </InputGroup>
                        {error.degree && (
                            <FormErrorMessage fontSize={14} color={"#F04438"}>Please Add degree or certificates</FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl mb={4} display={'flex'} alignItems={'stretch'} justifyContent={'flex-start'}>
                        <FormLabel ml={5} fontSize={'16px'} fontWeight={700} fontFamily={'Oxygen'}>Access</FormLabel>
                        <Switch onChange={(e) => setIsAccess(e.target.checked)} size='lg' />
                    </FormControl>
                    {isAccess && (
                        <FormControl mb={4}>
                            <InputGroup>
                                <InputLeftElement pointerEvents='none'>
                                    <EyeIcon />
                                </InputLeftElement>
                                <Input placeholder="add password" border={'1px solid rgba(116, 116, 116, 1)'} borderRadius={'8px'} value={password} onChange={(e) => setPassword(e.target.value)} />
                            </InputGroup>
                        </FormControl>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button variant="ghost"
                        mr={3}
                        onClick={onClose} w={'50%'} border={'1px solid rgba(116, 116, 116, 1)'}>
                        Cancel
                    </Button>
                    <Button colorScheme="blue" w={'50%'} onClick={handleAddData}>
                        {isEdit ? 'Update' : 'Add'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};

export default AddUserModal;
