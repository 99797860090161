import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  HStack,
  Textarea,
  Box,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import './appointmentModel.css'
import { CheckIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
const AddAppointmentModal = ({ isOpen, onClose, isEdit, selectedCell, times, handleAddAppointment, patientList, doctorList, daysheetDate, handleCheckDocAavailablity }) => {
  const [appointmentDate, setAppointmentDate] = useState(isEdit ? selectedCell.date : daysheetDate);
  const [startTime, setStartTime] = useState(selectedCell?.start ? selectedCell.start : "");
  const [endTime, setEndTime] = useState(isEdit ? selectedCell.end : "");
  const [openDropDown, setOpenDropDown] = useState(false);
  const [patientOpt, setPatientOpt] = useState([]);
  const [docAvailble, setDocAvailble] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState({
    patientId: isEdit ? selectedCell.patientId : "",
    patientName: isEdit ? selectedCell.patientName : "",
    patientMobileNo: isEdit ? selectedCell.patientNumber : "",
    patientNo: ""
  })

  const [selectedDoctor, setSelectedDoctor] = useState({
    person: isEdit ? selectedCell.doctorName : !isEdit && selectedCell?.doctorId ? selectedCell.doctorId[0].doctorName : '',
    doctorName: isEdit ? selectedCell.doctorName : !isEdit && selectedCell?.doctorId ? selectedCell.doctorId[0].doctorName : '',
    doctorId: isEdit ? selectedCell.doctorId : !isEdit && selectedCell?.doctorId ? selectedCell.doctorId[0].doctorId : '',
  })

  const [remarks, setRemarks] = useState(selectedCell && selectedCell?.remarks ? selectedCell.remarks : "")

  const navigate = useNavigate();

  const handleStartTimeChange = (value) => {
    setStartTime(value);
    // value = JSON.parse(value);
    // let EndData = allTime.splice(value.index + 1, times.length);
    // setEndTimeData(EndData);
    // setAllTime(times);
    // setStartTime(value.time);
  };

  useEffect(() => {
    if (isEdit) {
      let patientOption = patientList.filter((item) => item.patientId === selectedCell.patientId);
      setSelectedPatient(patientOption[0])
    }
    if (!isEdit && selectedCell.start) {
      let endTimes = ""
      times.filter((item, i) => {
        if (item === selectedCell.start) {
          endTimes = times[i + 1];
        }
      });
      setEndTime(endTimes)
    }
  }, [])

  const handleEndTimeChange = (value) => {
    setEndTime(value);
  };

  useEffect(() => {
    if (startTime !== "" && endTime !== "" && appointmentDate && selectedDoctor.doctorId !== "") {
      handleCheckAva()
    }
  }, [startTime, endTime, appointmentDate, selectedDoctor.doctorId])

  const handleCheckAva = async () => {
    let Data = {
      appointmentId: isEdit ? selectedCell.id : 0,
      doctorId: selectedDoctor.doctorId,
      start: startTime,
      end: endTime,
      date: appointmentDate,
    }
    const isAvailable = await handleCheckDocAavailablity(Data);
    if (isAvailable) {
      setDocAvailble(true)
      // handleAddAppointment(Data);
    } else {
      setDocAvailble(false)
      // toast.error('Doctor not available on ');
    }
  }

  const handleSave = async () => {

    if (selectedPatient.patientNo) {
      let Data = {
        id: isEdit ? selectedCell.id : null,
        patientId: selectedPatient.patientId,
        patientName: selectedPatient.patientName,
        patientNumber: selectedPatient.patientMobileNo,
        start: startTime,
        end: endTime,
        person: selectedDoctor.person,
        doctorId: selectedDoctor.doctorId,
        doctorName: selectedDoctor.doctorName,
        date: appointmentDate,
        remarks: remarks,
        appointmentStatus: isEdit ? selectedCell.appointmentStatus : ''
      };
      handleAddAppointment(Data);
    } else {
      navigate('/patients/add-patient');
    }
  }

  const handleSelectPatient = (value) => {
    if (value) {
      let patientOption = patientList.filter((item) => item.patientName.toLowerCase().includes(value.toLowerCase()));
      setPatientOpt(patientOption)
      setSelectedPatient({ ...selectedPatient, patientName: value })
      if (patientOption.length === 1 && patientOption[0].patientName.toLowerCase() === value.toLowerCase()) {
        setSelectedPatient(patientOption[0])
      } else {
        setSelectedPatient({
          patientId: '',
          patientName: value,
          patientMobileNo: '',
          patientNo: '',
        })
      }
    } else {
      setPatientOpt([])
      setSelectedPatient({
        patientId: '',
        patientName: value,
        patientMobileNo: '',
        patientNo: '',
      })
    }
  }
  const handleSelectDoctor = (value) => {
    const selectedDoc = doctorList.filter((item) => item.doctorId === Number(value));
    setSelectedDoctor({
      person: selectedDoc[0].doctorName,
      doctorName: selectedDoc[0].doctorName,
      doctorId: selectedDoc[0].doctorId,
    })
  }

  const handleSelectOption = (item) => {
    setOpenDropDown(false);
    setSelectedPatient(item);
  }

  const hanldeCheckClick = (e) => {
    if (e.target && e.target.className && e.target.className !== 'patient-opt-p' && e.target.className !== 'patient-opt-list') {
      setOpenDropDown(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Appointments</ModalHeader>
        <ModalCloseButton />
        <ModalBody onClickCapture={(e) => hanldeCheckClick(e)}>
          <FormControl mb={4}>
            <FormLabel>Patient Name</FormLabel>
            <InputGroup>
              <Input onChange={(e) => handleSelectPatient(e.target.value)} value={selectedPatient.patientName} aria-autocomplete="none" autoComplete="none" onClick={() => setOpenDropDown(true)} />
              <InputRightElement w={'auto'} mr={'15px'}>
                <CheckIcon w={'18px'} h={'18px'}
                  color={selectedPatient.patientNo ? '#89C068' : '#939393'}
                  mr={'4px'} />
                {selectedPatient.patientNo && (
                  <p style={{ fontSize: '10px' }}>
                    {selectedPatient.patientNo}
                  </p>
                )}
              </InputRightElement>
            </InputGroup>
            {openDropDown && patientOpt.length ? (
              <div className="patient-opt-list">
                {patientOpt.map((item, i) => {
                  return <div onClick={() => handleSelectOption(item)} className="patient-opt-p" key={i}>{item.patientName}</div>
                })}
              </div>
            ) : ('')}
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Patient's Mobile Number</FormLabel>
            <Input placeholder="add patient’s mobile number" value={selectedPatient.patientMobileNo} disabled />
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Doctor</FormLabel>
            <Select placeholder="Select Doctor" value={selectedDoctor.doctorId} onChange={(e) => handleSelectDoctor(e.target.value)}>
              {doctorList.map((item, i) => {
                return <option key={i} value={item.doctorId}>{item.doctorName}</option>
              })}
            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Date</FormLabel>
            <InputGroup>
              <Input type="date" value={appointmentDate} onChange={(e) => setAppointmentDate(e.target.value)} />
              <InputRightElement w={'auto'} mr={'15px'}>
                <div className="status-div" style={{ backgroundColor: docAvailble ? '#DCFCE7' : '#FEF9C3', color: docAvailble ? '#14532D' : '#713F12' }}>
                  <p className="status-doc" style={{ backgroundColor: docAvailble ? '#14532D' : '#FACC15' }}></p>
                  <p>{docAvailble ? 'Available' : 'unavailable'}</p>
                </div>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Time</FormLabel>
            <HStack>
              <Select onChange={(e) => handleStartTimeChange(e.target.value)} value={startTime}>
                {times.map((time, i) => {
                  return <option key={i} value={time}>{time}</option>
                })}
              </Select>
              <Box as="span" mx="2">
                →
              </Box>
              <Select disabled={startTime ? false : true} onChange={(e) => handleEndTimeChange(e.target.value)} value={endTime}>
                {times.map((time) => {
                  return <option key={time} value={time}>{time}</option>
                })}
              </Select>
            </HStack>
          </FormControl>
          <FormControl>
            <FormLabel>Comment</FormLabel>
            <Textarea placeholder="add comment" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            width={'100%'}
            variant="ghost"
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            width={"100%"}
            colorScheme="blue"
            onClick={handleSave}
            isDisabled={selectedPatient.patientNo && docAvailble === false ? true : false}
          >
            {selectedPatient.patientNo ? 'Add Appointment' : !selectedPatient.patientName ? 'Add Appointment' : 'Add Patient'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddAppointmentModal;
