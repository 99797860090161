import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../global/css/patient-list.css";
import api from "../../api";
import Topbar from "../dashboard/Topbar";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Tab,
    Table,
    TableContainer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import {
    MdExpandLess,
    MdExpandMore,
} from "react-icons/md";
import { toast } from "react-toastify";

const AddEditUserRole = () => {
    const navigate = useNavigate();

    const [permissionTable, setPermissionTable] = useState([]);
    const [roleNumber, setRoleNumber] = useState('2');
    const [roleName, setRoleName] = useState('');
    const [openBox, setOpenBox] = useState(false);
    const [selected, setSelected] = useState(null);
    const [deletedPermission, setDeletedPermission] = useState([]);
    const handleOpen = (id) => {
        if (selected && selected === id) {
            setOpenBox(false);
            setSelected(null)
        } else {
            setOpenBox(true)
        }
        setSelected(id);
    }
    const [apiSaveData, setApiSaveData] = useState([])

    useEffect(() => {
        let permissionsDatas = JSON.parse(localStorage.getItem('rolestable'));
        if (permissionsDatas) {
            let defaultPermission = handleDefaultValueUpdate(permissionsDatas);
            setPermissionTable(defaultPermission)
        } else {
            handleFetchPermissionTableData();
        }
        let id = window.location.pathname.split('/')[2];
        if (id && id !== 'create') {
            setRoleNumber(id)
        }
    }, [])

    const handleFetchPermissionTableData = async (id) => {
        try {
            const response = await api.get("/user/role/2");
            if (response.data.status) {
                let defaultPermission = handleDefaultValueUpdate(response.data.response.menupermissions);
                setPermissionTable(defaultPermission);
                localStorage.setItem('rolestable', JSON.stringify(response.data.response.menupermissions))
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleDefaultValueUpdate = (data) => {
        let defaultPermission = [];
        data.map((item) => {
            if (item.subMenu.length === 1) {
                let update = item
                update.subMenu[0].action = []
                defaultPermission.push(update)
            } else {
                let updateValue = item
                let bothItem = [];
                item.subMenu.map((sub, p) => {
                    let subItem = sub
                    subItem.action = [];
                    bothItem.push(subItem)
                })
                updateValue.subMenu = bothItem;
                defaultPermission.push(updateValue)
            }
        })
        return defaultPermission;
    }

    useEffect(() => {
        if (roleNumber !== '2') {
            handleFetchTableData();
        }
    }, [roleNumber])

    const handleFetchTableData = async (id) => {
        try {
            const response = await api.get(`/user/role/${roleNumber}`);
            if (response.data.status) {
                let data = JSON.parse(localStorage.getItem('rolestable'));
                data.forEach(menu => {
                    menu.subMenu.forEach(subMenu => {
                        const matchingSubMenu = response.data.response.menupermissions
                            .find(item => item.navMenuId === menu.navMenuId)
                            ?.subMenu
                            .find(sub => sub.title === subMenu.title);

                        if (matchingSubMenu) {
                            subMenu.action = matchingSubMenu.action;
                        } else {
                            subMenu.action = [];
                        }
                    });
                });
                setPermissionTable(data);
                setRoleNumber(response.data.response.roleId);
                setRoleName(response.data.response.roleName);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSelectPermission = (value, title, subTitle, subId, selectableRows) => {
        let preData = permissionTable
        let updatedData = [];
        let deletePermission = deletedPermission;
        preData.map((item, i) => {
            if (item.title === title) {
                item.subMenu.map((sub, j) => {
                    if (sub.title === subTitle) {
                        if (value) {
                            let addedData = item
                            addedData.subMenu[j].action.push(selectableRows)
                            updatedData.push(addedData)
                            if (deletePermission.length) {
                                let updateDelete = []
                                deletePermission.map((deletePer) => {
                                    if (deletePer.navSubMenuId === subId && deletePer.navMenuActionId === selectableRows.navMenuActionId) {
                                        console.log("add");
                                    } else {
                                        updateDelete.push(deletePer)
                                    }
                                });
                                deletePermission = updateDelete
                            }
                        }
                        if (value === false) {
                            let removedData = item
                            let permissData = removedData.subMenu[j].action.filter((per) => per.navMenuActionId !== selectableRows.navMenuActionId)
                            let removedPermission = removedData.subMenu[j].action.filter((per) => per.navMenuActionId === selectableRows.navMenuActionId)
                            removedPermission = {
                                navMenuActionId: removedPermission[0].navMenuActionId,
                                navSubMenuId: subId
                            }
                            removedData.subMenu[j].action = permissData
                            updatedData.push(removedData)
                            deletePermission.push(removedPermission)
                        }
                    }
                })
            } else {
                updatedData.push(item)
            }
        })
        setDeletedPermission(deletePermission)
        setPermissionTable(updatedData);
    }

    const handleSavePermission = async () => {
        let permissionsArray = []
        permissionTable.map((item, i) => {
            item.subMenu.map((sub) => {
                if (sub.action.length) {
                    let permissions = {
                        navSubMenuId: sub.navSubMenuId,
                        navMenuActionVos: sub.action
                    }
                    permissionsArray.push(permissions)
                }
            })
        })
        let dataParam = {
            roleId: roleNumber !== '2' ? roleNumber : 0,
            roleName: roleNumber !== '2' ? roleName : roleName,
            menuPermissions: permissionsArray,
            deletePermissions: roleNumber !== '2' ? deletedPermission : []
        }
        try {
            const result = await api.post(`/user/role/save`, dataParam);
            if (result.data.status) {
                navigate(`/roles-permissions`)
                toast.success("Role Successfully Added!");
            } else {
                toast.error(result.data.message);
            }
        } catch (err) {
            toast.error("Something went wrong!");
        }
    }

    const handleCancel = () => {
        navigate(`/roles-permissions`)
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            w={"100%"}
            h={"100vh"}
            bg={"#F8FAFF"}
        >
            <Box as="header">
                <Topbar title="Users" />
            </Box>
            <Box
                m={"0 16px 0px"}
                pt={"16px"}
                borderRadius="8px 8px 0px 0px"
                boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
                bg={"white"}
            >
                <Flex
                    p={"0px 16px 16px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Text color="#333" fontSize={"20px"} fontWeight={700}>
                        {"User Role"}
                    </Text>
                </Flex>
            </Box>

            <Box
                m={"0 16px 0px"}
                borderRadius="0px 0px 0px 0px"
                boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
                bg={"#EFF4FE"}
                alignItems={'center'}
                minH={'40px'}
                display={'flex'}
            >
                <HStack justify="space-between">
                    <Flex
                        alignItems={"center"}
                        gap={"16px"}
                        cursor={"pointer"}
                        ml={'15px'}
                    >
                        <Text fontSize="16px" fontWeight="400" color={"black"}>
                            {roleNumber}
                        </Text>
                        {roleNumber !== '2' ?
                            <Text fontSize="16px" fontWeight="400" color={"black"}>
                                {roleName}
                            </Text>
                            :
                            <Input
                                w={"244px"}
                                color={"#747474"}
                                _placeholder={{ fontSize: "12px" }}
                                placeholder="Add Role Name"
                                border={"1px solid #4C8BF5"}
                                onChange={(e) => setRoleName(e.target.value)}
                                value={roleName}
                            />
                        }
                    </Flex>
                </HStack>
                {/* <Text ml={'20px'} fontSize="16px" fontWeight="400" color={"black"}>1 Admin</Text> */}
            </Box>
            {permissionTable.map((item, i) => {
                return <Box
                    m={"0 16px 0px"}
                    pt={"16px"}
                    borderRadius="0px 0px 0px 0px"
                    bg={"white"}
                >
                    <Box
                        m={"0 16px 16px"}
                        // pt={"16px"}
                        borderRadius="4px 4px 4px 4px"
                        boxShadow={"0px 0px 15px 2px rgba(0, 0, 0, 0.05)"}
                        bg={"white"}
                        border={'1px solid black'}
                        minH={'40px'}
                        alignContent={'center'}
                    >
                        <HStack justify="space-between" mt={openBox && selected === i.toString() ? '10px' : '0px'} onClick={() => handleOpen(i.toString())}>
                            <Flex
                                alignItems={"center"}
                                gap={"16px"}
                                cursor={"pointer"}
                                ml={'15px'}
                            >
                                <Text fontSize="16px" fontWeight="400" color={"black"}>
                                    {item.title}
                                </Text>
                            </Flex>
                            <HStack alignItems={"center"}>
                                {openBox && selected === i.toString() ?
                                    <>
                                        <MdExpandLess /> <Text ml={3}></Text>
                                    </>
                                    :
                                    <>
                                        <MdExpandMore /> <Text ml={3}></Text>
                                    </>
                                }
                            </HStack>
                        </HStack>
                        {openBox && selected === i.toString() &&
                            <TableContainer m={'15px 30px 5px 30px'} borderRadius={'8px'}>
                                <Table size='sm'>
                                    <Thead backgroundColor={'#DEE9FE'}>
                                        <Tr>
                                            <Th></Th>
                                            <Th>Read</Th>
                                            <Th>Write</Th>
                                            <Th>Edit</Th>
                                            <Th>Delete</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody backgroundColor={'#EFF4FE'}>
                                        {item.subMenu.map((tableItem, t) => {
                                            return <Tr borderBottom={'1px solid #C1D6FF'}>
                                                <Td borderBottom={item.subMenu.length > (t + 1) ? 'none' : '1px solid #EFF4FE'}>{tableItem.title}</Td>
                                                <Td borderBottom={item.subMenu.length > (t + 1) ? 'none' : '1px solid #EFF4FE'}><Checkbox size='lg' border={'1px #4C8BF5'} defaultChecked={tableItem.action.filter((actionMenu) => actionMenu.navMenuActionId === 3)?.length > 0 ? true : false} onChange={(e) => handleSelectPermission(e.target.checked, item.title, tableItem.title, tableItem.navSubMenuId, { navMenuActionId: 3, actionName: 'VIEW' })} colorScheme='green'></Checkbox></Td>
                                                <Td borderBottom={item.subMenu.length > (t + 1) ? 'none' : '1px solid #EFF4FE'}><Checkbox size='lg' border={'1px #4C8BF5'} defaultChecked={tableItem.action.filter((actionMenu) => actionMenu.navMenuActionId === 1)?.length > 0 ? true : false} onChange={(e) => handleSelectPermission(e.target.checked, item.title, tableItem.title, tableItem.navSubMenuId, { navMenuActionId: 1, actionName: 'INSERT' })} colorScheme='green'></Checkbox></Td>
                                                <Td borderBottom={item.subMenu.length > (t + 1) ? 'none' : '1px solid #EFF4FE'}><Checkbox size='lg' border={'1px #4C8BF5'} defaultChecked={tableItem.action.filter((actionMenu) => actionMenu.navMenuActionId === 2)?.length > 0 ? true : false} onChange={(e) => handleSelectPermission(e.target.checked, item.title, tableItem.title, tableItem.navSubMenuId, { navMenuActionId: 2, actionName: 'EDIT' })} colorScheme='green'></Checkbox></Td>
                                                <Td borderBottom={item.subMenu.length > (t + 1) ? 'none' : '1px solid #EFF4FE'}><Checkbox size='lg' border={'1px #4C8BF5'} defaultChecked={tableItem.action.filter((actionMenu) => actionMenu.navMenuActionId === 4)?.length > 0 ? true : false} onChange={(e) => handleSelectPermission(e.target.checked, item.title, tableItem.title, tableItem.navSubMenuId, { navMenuActionId: 4, actionName: 'DELETE' })} colorScheme='green'></Checkbox></Td>
                                            </Tr>
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        }
                    </Box>
                </Box>
            })}
            <Flex
                justifyContent={'flex-end'}
                alignItems={"center"}
                gap={"16px"}
                mr={'30px'}
            >
                <Button
                    variant="ghost"
                    mr={3}
                    w={'150px'}
                    border={'1px solid black'}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    colorScheme="blue"
                    w={'150px'}
                    onClick={handleSavePermission}
                >
                    Save
                </Button>
            </Flex>

        </Box>
    );
};

export default AddEditUserRole;
