import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  RadioGroup,
  Stack,
  Button,
  IconButton,
  HStack,
  Flex,
  Text,
  Card,
  CardHeader,
  Heading,
  CardBody,
  CardFooter,
  Divider,
  StackDivider,
} from "@chakra-ui/react";
import CustomDatePicker from "../../../components/CustomDatePicker";
import usePatientForm from "../../../hooks/usePatientForm";
import { differenceInYears } from "date-fns";
import api from "../../../api";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import CustomCheckbox from "../../../components/CustomCheckbox";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import PlusSquareIcon from "../../../components/icons/PlusSquareIcon";

import "../../../global/css/patient-new-form.css";

const PatientForm = () => {
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [age, setAge] = useState("0");
  const {
    formData,
    formErrors,
    handleInputChange,
    handleContactsChange,
    validate,
    addContact,
    removeContact,
    fetchDataPatientInfo
  } = usePatientForm();

  console.log("formErrors ", formErrors);

  useEffect(() => {
    // Check if the URL matches the pattern /patients/${id}/edit
    if (location.pathname === `/patients/${param.id}/edit`) {
      fetchDataPatientInfo(param.id); // Call the fetch function
    }
  }, [param.id, location.pathname]); // Dependencies: id and pathname

  useEffect(() => {
    let calculatedAge = differenceInYears(new Date(), (formData.patientDob ? formData.patientDob : new Date()));
    setAge(calculatedAge);
  }, [formData])
  

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("FORM DATA ::: " , formData)
    if (validate()) {
      // if (false) {
      // Form is valid, proceed with form submission
        try {
          const result = await api.post("/patient/save", {
            ...formData,
            contactDetails: [...formData.contactDetails],
            patientMobileno: formData.contactDetails[0].patientContactMobileNo,
            patientId: formData.patientId,
            patientCountryCode: process.env.REACT_APP_COUNTRY_CODE,
            patientStateCode: process.env.REACT_APP_STATE_CODE,
            patientDob: moment(formData.patientDob).format("DD-MM-yyyy"),
            appointmentDate: moment(formData.appointmentDate).format(
              "DD-MM-yyyy"
            ),
            // deleteContacts : "",
          });
          console.log("result ", result.data);
          if (result.data.status) {
            toast.success("Patient Added!");
            navigate("/patients");
          } else {
            console.log("result.message ", result.data.message);
            toast.error(result.data.message);
          }
        } catch (err) {
          console.log("err", err);
          toast.error("Something went wrong!");
        } finally {
          setLoading(false);
        }
    }
  };

  return (
    <Box width="100%" borderWidth={1} borderRadius="lg">
      <FormControl as="fieldset" mb={4} fontFamily="Oxygen">
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          h={"50px"}
          borderBottom={"2px solid #E8E8E8"}
          p={3}
        >
          <FormLabel
            flex={1}
            as="legend"
            fontFamily="Oxygen"
            fontSize={"20px"}
            fontWeight={700}
          >
            Add patient’s information
          </FormLabel>
        </Flex>

        <Box p={4}>
          <HStack spacing={4} mb={1}>
            <Box flex="2">
              <FormControl isInvalid={!!formErrors.patientFirstname}>
                <FormLabel fontWeight={700}>
                  Name<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  border="1.4px solid #D2D2D2"
                  placeholder="Name"
                  name="patientFirstname"
                  background="#F8FAFF"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={formData.patientFirstname}
                />
                {formErrors.patientFirstname ? (
                  <small style={{ color: "red" }}>
                    {formErrors.patientFirstname}
                  </small>
                ) : (
                  <small>&nbsp;</small>
                )}
              </FormControl>
            </Box>
            {/* <Box flex="1">
              <FormControl isInvalid={!!formErrors.patientLastname}>
                <FormLabel fontWeight={700}>&nbsp;</FormLabel>
                <Input
                  border="1.4px solid #D2D2D2"
                  name="patientLastname"
                  placeholder="Last name"
                  background="#F8FAFF"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {formErrors.patientLastname ? (
                  <small style={{ color: "red" }}>
                    {formErrors.patientLastname}
                  </small>
                ) : (
                  <small>&nbsp;</small>
                )}
              </FormControl>
            </Box> */}

            <Box flex="1">
              <FormControl>
                <FormLabel fontWeight={700}>
                  Gender
                </FormLabel>
                <RadioGroup
                  onChange={(value) => {
                    let e = {
                      target: {
                        value: value,
                        name: "patientGender",
                      },
                    };
                    handleInputChange(e);
                  }}
                >
                  <Stack direction="row" gap={"16px"}>
                    <CustomCheckbox
                      minWidth={"24px"}
                      color={"black"}
                      checked={formData.patientGender === "male"}
                      onChange={(value) => {
                        let e = {
                          target: {
                            value: value.target.checked ? "male" : "",
                            name: "patientGender",
                          },
                        };
                        handleInputChange(e);
                      }}
                      value="male"
                    >
                      Male
                    </CustomCheckbox>
                    <CustomCheckbox
                      width={"24px"}
                      color={"black"}
                      checked={formData.patientGender === "female"}
                      onChange={(value) => {
                        let e = {
                          target: {
                            value: value.target.checked ? "female" : "",
                            name: "patientGender",
                          },
                        };
                        handleInputChange(e);
                      }}
                      value="female"
                    >
                      Female
                    </CustomCheckbox>
                  </Stack>
                </RadioGroup>
                {formErrors.patientGender ? (
                  <small style={{ color: "red" }}>
                    {formErrors.patientGender}
                  </small>
                ) : (
                  <small>&nbsp;</small>
                )}
              </FormControl>
            </Box>
          </HStack>

          <HStack spacing={4} mb={1}>
            <Box flex="1">
              <FormControl>
                <FormLabel fontWeight={700}>Email</FormLabel>
                <Input
                  border="1.4px solid #D2D2D2"
                  name="patientEmail"
                  placeholder="Email Id"
                  background="#F8FAFF"
                  value={formData.patientEmail}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {formErrors.patientEmail ? (
                  <small style={{ color: "red" }}>
                    {formErrors.patientEmail}
                  </small>
                ) : (
                  <small>&nbsp;</small>
                )}
              </FormControl>
            </Box>

            <Box flex="1">
              <FormLabel fontWeight={700} style={{display:'flex',justifyContent:'space-between'}}>
                <span>Birth Date</span>
                <span>( {age} years old ) </span>
              </FormLabel>

              <CustomDatePicker
                age={age}
                isDOB={1}
                style={{ color: "width : 100%" }}
                selectedDate={
                  formData.patientDob ? formData.patientDob : new Date()
                }
                value={formData.patientDob ? formData.patientDob : new Date()}
                maxDate={new Date()}
                popperPlacement="bottom-start"
                onDateChange={(date) => {
                  let e = {
                    target: {
                      value: date,
                      name: "patientDob",
                    },
                  };
                  handleInputChange(e);
                  let calculatedAge = differenceInYears(new Date(), (formData.patientDob ? formData.patientDob : date));
                  setAge(calculatedAge);
                }}
              />
              {formErrors.patientDob ? (
                <small style={{ color: "red" }}>{formErrors.patientDob}</small>
              ) : (
                <small>&nbsp;</small>
              )}
            </Box>

            <Box flex="1">
              <FormControl>
                <FormLabel fontWeight={700}>Refer by</FormLabel>
                <Input
                  border="1.4px solid #D2D2D2"
                  name="referenceBy"
                  background="#F8FAFF"
                  placeholder="Write doctor or Clinic name"
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  value={formData.referenceBy}
                />
                {formErrors.referenceBy ? (
                  <small style={{ color: "red" }}>
                    {formErrors.referenceBy}
                  </small>
                ) : (
                  <small>&nbsp;</small>
                )}
              </FormControl>
            </Box>

            {/* <Box flex="1">
              <FormControl>
                <FormLabel fontWeight={700} >
                  Age
                </FormLabel>
                <Input
                  isDisabled
                  _disabled={{ color: "black" }}
                  border="1.4px solid #D2D2D2"
                  defaultValue={age ? `${age} years old` : ""}
                  value={age ? `${age} years old` : ""}
                />
              </FormControl>
            </Box> */}
          </HStack>

          <HStack spacing={4} mb={1}>
            <Box flex="1"></Box>
          </HStack>

          <Flex flexDir={"column"} alignItems={"center"} spacing={4} mb={1}>
            <Box w={"100%"}>
              <FormLabel fontWeight={700}>Contacts</FormLabel>
            </Box>
            {formData.contactDetails.map((details, index) => (
              <Flex
                w={"100%"}
                gap={4}
                key={details.patientContactIndex}
                // mb={index + 1 !== formData.contactDetails.length ? 1 : ""}
              >
                <FormControl
                  flex={1}
                  isInvalid={
                    !!formErrors.contactDetails[index]?.patientContactMobileNo
                  }
                >
                  <Input
                    border="1.4px solid #D2D2D2"
                    name="patientContactMobileNo"
                    placeholder="Mobile*"
                    background="#F8FAFF"
                    value={formData.contactDetails[index]?.patientContactMobileNo}
                    onChange={(e) => {
                      handleContactsChange(e, details.patientContactIndex);
                    }}
                  />
                  <Input type="hidden" value={formData.contactDetails[index]?.patientContactId}/>
                  {formErrors.contactDetails[index]?.patientContactMobileNo ? (
                    <small style={{ color: "red" }}>
                      {formErrors.contactDetails[index].patientContactMobileNo}
                    </small>
                  ) : (
                    <small>&nbsp;</small>
                  )}
                </FormControl>

                {((formData.contactDetails[index]?.patientContactBelongsTo != "other") ? (<FormControl
                  flex={1}
                  isInvalid={
                    !!formErrors.contactDetails[index]?.patientContactBelongsTo
                  }
                >
                  <Select
                    w={"100%"}
                    border="1.4px solid #D2D2D2"
                    name="patientContactBelongsTo"
                    background="#F8FAFF"
                    color={"#747474"}
                    placeholder="Belongs to*"
                    value={formData.contactDetails[index]?.patientContactBelongsTo}
                    onChange={(e) => {
                      handleContactsChange(e, details.patientContactIndex);
                    }}
                  >
                    <option value="self">Self</option>
                    <option value="relative">Relative</option>
                    <option value="other">Other</option>
                  </Select>
                  {formErrors.contactDetails[index]?.patientContactBelongsTo ? (
                    <small style={{ color: "red" }}>
                      {formErrors.contactDetails[index].patientContactBelongsTo}
                    </small>
                  ) : (
                    <small>&nbsp;</small>
                  )}
                </FormControl>) : (''))}
                
                {
                  ((formData.contactDetails[index]?.patientContactBelongsTo == "other") ? (<FormControl flex={1}>
                    <Input
                        border="1.4px solid #D2D2D2"
                        name="patientContactOther"
                        placeholder="Belongs to name*"
                        background="#F8FAFF"
                        value={formData.contactDetails[index]?.patientContactOther}
                        onChange={(e) => {
                          handleContactsChange(e, details.patientContactIndex);
                        }}
                      />
                        {formErrors.contactDetails[index]?.patientContactOther ? (
                          <small style={{ color: "red" }}>
                            {formErrors.contactDetails[index].patientContactOther}
                          </small>
                        ) : (
                          <small>&nbsp;</small>
                        )}
                    </FormControl>) : (''))
                }
                <Flex flex={1}>
                  {index !== 0 && (
                    <Box>
                      <IconButton
                        mr={2}
                        aria-label="Remove contact"
                        icon={<DeleteIcon />}
                        onClick={() => removeContact(details.patientContactId,details.patientContactIndex)}
                        variant="outline"
                        alignSelf="center"
                      />
                      <small>&nbsp;</small>
                    </Box>
                  )}
                  {index === formData.contactDetails.length - 1 && (
                    <Box>
                      <IconButton
                        aria-label="Add contact"
                        icon={<PlusSquareIcon />}
                        onClick={addContact}
                        variant="outline"
                        alignSelf="center"
                      />
                      <small>&nbsp;</small>
                    </Box>
                  )}
                </Flex>
              </Flex>
            ))}
          </Flex>

          <HStack spacing={4} mb={1}>
            <Box flex="1">
              <FormControl>
                <FormLabel fontWeight={700}>
                  Address
                </FormLabel>
                <Input
                  border="1.4px solid #D2D2D2"
                  placeholder="Address Line 1"
                  name="patientAddressLine1"
                  background="#F8FAFF"
                  value={formData.patientAddressLine1}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {formErrors.patientAddressLine1 ? (
                  <small style={{ color: "red" }}>
                    {formErrors.patientAddressLine1}
                  </small>
                ) : (
                  <small>&nbsp;</small>
                )}
              </FormControl>
            </Box>

            <Box flex="1">
              <FormControl>
                <FormLabel fontWeight={700}>Pin Code</FormLabel>
                <Input
                  border="1.4px solid #D2D2D2"
                  name="patientPincode"
                  background="#F8FAFF"
                  placeholder="Pin Code"
                  value={formData.patientPincode}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {formErrors.patientPincode ? (
                  <small style={{ color: "red" }}>
                    {formErrors.patientPincode}
                  </small>
                ) : (
                  <small>&nbsp;</small>
                )}
              </FormControl>
            </Box>
          </HStack>

          <HStack spacing={4} mb={1}>
            <Box flex="1">
              <Input
                border="1.4px solid #D2D2D2"
                name="patientAddressLine2"
                placeholder="Address Line 2"
                background="#F8FAFF"
                onChange={(e) => {
                  handleInputChange(e);
                }}
                value={formData.patientAddressLine2}
              />
              <small>&nbsp;</small>
            </Box>

            <Box flex="1">
              <FormControl isInvalid={!!formErrors.patientCityCode}>
                <Input
                  border="1.4px solid #D2D2D2"
                  placeholder="City"
                  name="patientCityCode"
                  background="#F8FAFF"
                  value={formData.patientCityCode}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                {formErrors.patientCityCode ? (
                  <small style={{ color: "red" }}>
                    {formErrors.patientCityCode}
                  </small>
                ) : (
                  <small>&nbsp;</small>
                )}
              </FormControl>
            </Box>
          </HStack>
        </Box>

        {/* As per UI added white space */}
        {/* <Box h={"42px"}></Box> */}

        <HStack
          justifyContent={"space-between"}
          spacing={4}
          mb={4}
          p={4}
          verticalAlign={"top"}
        >
          <Box>
            <FormLabel fontWeight={700}>Select appointment date</FormLabel>
            <CustomDatePicker
              isDOB={0}
              selectedDate={
                formData.appointmentDate ? formData.appointmentDate : new Date()
              }
              minDate={new Date()}
              onDateChange={(date) => {
                let e = {
                  target: {
                    value: date,
                    name: "appointmentDate",
                  },
                };
                handleInputChange(e);
              }}
            />
            {formErrors.appointmentDate ? (
              <small style={{ color: "red" }}>
                {formErrors.appointmentDate}
              </small>
            ) : (
              <small>&nbsp;</small>
            )}
          </Box>

          <Box>
            <Button
              alignSelf={"end"}
              w={"246px"}
              h={"48px"}
              background={"#4C8BF5"}
              color={"white"}
              _hover={{
                opacity: 0.9,
              }}
              style={{ verticalAlign: "middle" }}
              fontSize={"20px"}
              fontWeight={700}
              fontFamily={"'Open Sans'"}
              onClick={submitHandler}
            >
              Submit
            </Button>
            <small>&nbsp;</small>
          </Box>
        </HStack>
      </FormControl>
    </Box>
  );
};

export default PatientForm;
